import { getAuthState, logout } from "../services/Authentication";

const basePath = "https://server.npower.andrade.pt/api/";

function checkStatus(response) {
  if (response.status === 204) {
    return "ok";
  } else if (response.status === 401 || response.status === 403) {
    logout();
    window.location = "/login";
  } else if (response.ok) {
    return response.json();
  } else {
    return Promise.reject(response);
  }
}

function throwError(error) {
  console.log(error);
  const throwError = new Error(`HTTP Error ${error.statusText}`);
  throwError.status = error.status;
  throwError.response = error.response;
  throwError.code = error.status;
  throw throwError;
}

export const refreshToken = (token) => {
  const body = {
    refreshToken: token,
  };
  return fetch(basePath + `auth/refresh`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    mode: "cors",
    body: JSON.stringify(body),
  })
    .then((response) => checkStatus(response))
    .catch(() => {
      logout();
    });
};

export const validateUser = (username, password) => {
  const login = {
    username: username,
    password: password,
  };
  return fetch(basePath + `auth/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    },
    mode: "cors",
    body: JSON.stringify(login),
  })
    .then((response) => {
      if (response.status !== 200) {
        return { status: response.status, body: "" };
      }
      return response.json();
    })
    .then((response) => {
      if (response.body === "") {
        return response;
      } else {
        return { status: 200, body: response };
      }
    })
    .catch((err) => {
      console.log("fetch error" + err);
    });
};

export const getHttp = (path, token = getAuthState().userToken) => {
  return fetch(basePath + path, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
  })
    .then((response) => checkStatus(response))
    .catch((err) => {
      console.log("fetch error" + err);
    });
};

export const deleteHttp = (path, token = getAuthState().userToken) => {
  return fetch(basePath + path, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => checkStatus(response))
    .catch((err) => {
      console.log("fetch error" + err);
    });
};

export const postHttp = (path, data, token = getAuthState().userToken) => {
  return fetch(basePath + path, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => checkStatus(response))
    .catch((error) => throwError(error));
};

export const patchHttp = (path, data, token = getAuthState().userToken) => {
  return fetch(basePath + path, {
    method: "PATCH",
    headers: {
      Accept: "application/json-patch+json",
      "Content-Type": "application/json-patch+json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  })
    .then((response) => checkStatus(response))
    .catch((error) => {
      throw error;
    });
};

export const getBinaryHttp = (path, token = getAuthState().userToken) => {
  return fetch(basePath + path, {
    method: "GET",
    headers: {
      Accept: "application/octet-stream",
      "Content-Type": "application/octet-stream",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: `Bearer ${token}`,
    },
    mode: "cors",
  })
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        return response.blob();
      } else {
        return Promise.reject(response);
      }
    })
    .catch((error) => {
      throw error;
    });
};
