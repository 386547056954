import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../services/Authentication";
import {
  Table,
  Pagination,
  Badge,
  Alert,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import {
  faHouseChimney,
  faSort,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { getHttp, patchHttp } from "../../helpers/Http";
import Loading from "../loading/Loading";
import DownloadButton from "../exports/DownloadButton";
import "./Surveys.css";
import NewProjectModal from "./NewProjectModal";
import EditProjectModal from "./EditProjectModal";

export default function Surveys() {
  const [show, setShow] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showErrorList, setShowErrorList] = useState(false);
  const [surveys, setSurveys] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [editModal, setEditModal] = useState(false);
  const [message, setMessage] = useState("");
  const [orderBy, setOrderBy] = useState(0);
  const [listTecnicos, setListTecnicos] = useState([]);
  const [pageSize, setPageSize] = useState("50");
  const [pageNumber, setPageNumber] = useState("0");

  const history = useNavigate();

  const [editProject, setEditProject] = useState({
    plr: "",
    assignedTechnicians: "",
  });

  const updateListNewItem = (item) => {
    if (item != "" && item != null && item != undefined) {
      const updatedArray = [item, ...surveys];
      setSurveys(updatedArray);
    }
  };

  const updateListEditItem = (item) => {
    const nextSurveys = surveys.map((c) => {
      if (c.id === item.id) {
        return item;
      } else {
        return c;
      }
    });
    setSurveys(nextSurveys);
  };

  const [filter, setFilter] = useState({
    id: "",
    plr: "",
    assignedTechnicians: "",
    endDate: "",
    createdDate: "",
    status: "",
  });

  const handleClose = () => {
    setShow(false);
    setEditModal(false);
  };

  const handleShow = () => {
    setShowError(false);
    setShowSuccess(false);
    setShow(true);
  };

  const showMessage = (type, message) => {
    if (type === 1) {
      setShowError(false);
      setShowSuccess(true);
      setMessage(message);
    } else if (type === 2) {
      setShowSuccess(false);
      setShowError(true);
      setMessage(message);
    }
  };

  const nextPage = () => {
    requestProjects(
      "projects?orderBy=id&page=" +
        (activePage + 1) +
        "&sort=DESC&size=" +
        pageSize
    );
  };

  const previousPage = () => {
    requestProjects(
      "projects?orderBy=id&page=" +
        (activePage - 1) +
        "&sort=DESC&size=" +
        pageSize
    );
  };

  const lastPage = () => {
    requestProjects(
      "projects?orderBy=id&page=" + totalPages + "&sort=DESC&size=" + pageSize
    );
  };

  const firstPage = () => {
    requestProjects("projects?orderBy=id&page=0&sort=DESC&size=" + pageSize);
  };

  const handleSort = (value) => {
    if (orderBy === 0) {
      setOrderBy(1);
      requestProjects(
        "projects?orderBy=" + value + "&sort=ASC&size=" + pageSize
      );
    } else {
      setOrderBy(0);
      requestProjects(
        "projects?orderBy=" + value + "&sort=DESC&size=" + pageSize
      );
    }
  };

  const requestProjects = (path) => {
    getHttp(path)
      .then((data) => {
        setSurveys(data.content);
        setTotalPages(0);
        createPagination(data.totalPages, data.pageNumber);
        setShowLoading(false);
        setShowErrorList(false);
      })
      .catch((e) => {
        if (e.code == 401) {
          logout().then(() => {
            history("/login");
          });
        }
        setShowLoading(false);
        setShowErrorList(true);
        setShowError(true);
        setMessage("Erro a mostrar os Projetos.");
      });
  };

  const updateProject = (id, patch) => {
    patchHttp("projects/" + id, patch)
      .then((data) => {
        updateListEditItem(data);
        setShowLoading(false);
        setShowError(false);
        setShowSuccess(true);
        setMessage("Projeto atualizado com sucesso!");
      })
      .catch((e) => {
        console.log(e);
        setShowLoading(false);
        setShowSuccess(false);
        setShowError(true);
        setMessage("Erro a atualizar o Projeto.");
      });
  };

  const createPagination = (total, active) => {
    setTotalPages(total - 1);
    setActivePage(active);
    setItems([]);
    const i = [];

    if (total <= 7) {
      for (let number = 0; number <= total - 1; number++) {
        i.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => setPageNumber(number + 1)}
            disabled={active + 1 === totalPages}
          >
            {number + 1}
          </Pagination.Item>
        );
      }
    } else {
      if (active > 0) {
        i.push(
          <Pagination.Ellipsis />,
          <Pagination.Item
            key={active}
            active={false}
            onClick={() => setPageNumber(active - 1)}
            disabled={false}
          >
            {active}
          </Pagination.Item>
        );
      }
      i.push(
        <Pagination.Item
          key={active + 1}
          active={true}
          onClick={() => setPageNumber(active)}
          disabled={false}
        >
          {active + 1}
        </Pagination.Item>
      );
      if (active <= total - 2) {
        i.push(
          <Pagination.Item
            key={active + 2}
            active={false}
            onClick={() => setPageNumber(active + 1)}
            disabled={active + 3 === totalPages}
          >
            {active + 2}
          </Pagination.Item>,
          <Pagination.Ellipsis />
        );
      }
    }
    setItems(i);
  };

  const handleSearchInput = (e) => {
    let { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitSearch = (e) => {
    if (e.key === "Enter" || e.key === undefined) {
      var query = "projects?";
      if (filter.id.length > 0) {
        query += "id=" + filter.id + "&";
      }
      if (filter.plr.length > 0) {
        query += "plr=" + filter.plr + "&";
      }
      if (filter.assignedTechnicians.length > 0) {
        query += "assignedTechnicians=" + filter.assignedTechnicians + "&";
      }
      if (filter.status.length > 0) {
        if (filter.status === "1") {
          query += "status=OPEN&";
        } else if (filter.status === "2") {
          query += "status=CLOSED&";
        }
      }
      if (filter.createdDate.length > 0) {
        query +=
          "createdDate=" + new Date(filter.createdDate).toISOString() + "&";
      }
      if (filter.endDate.length > 0) {
        query += "endDate=" + new Date(filter.endDate).toISOString() + "&";
      }

      query += "orderBy=id&sort=DESC&size=" + pageSize;
      requestProjects(query);
    }
  };

  // Handling the Status change
  const handleStatus = (id, value, index) => {
    if (value === "EDIT") {
      setEditProject(surveys[index]);
      setEditModal(true);
      return;
    } else {
      setShowLoading(true);
      var patch = [{ op: "replace", path: "/status", value: value }];
      updateProject(id, patch);
    }
  };

  useEffect(() => {}, [totalPages]);

  useEffect(() => {
    requestProjects(
      "projects?orderBy=id&page=" + pageNumber + "&sort=DESC&size=" + pageSize
    );
  }, [pageNumber]);

  useEffect(() => {
    requestProjects(
      "projects?orderBy=id&page=" + pageNumber + "&sort=DESC&size=" + pageSize
    );
  }, [pageSize]);

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    requestProjects(
      "projects?orderBy=id&page=" + pageNumber + "&sort=DESC&size=" + pageSize
    );

    // Get TECHNICIAN to associate to project
    getHttp("users/role/ROLE_TECHNICIAN?userStatus=ENABLED")
      .then((data) => {
        setShowSuccess(false);
        setShowError(false);
        setListTecnicos(data);
      })
      .catch(() => {
        setShowSuccess(false);
        setShowError(true);
        setMessage("Erro a carregar técnicos.");
      });
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div>
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">NPower Projetos</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">
                          <FontAwesomeIcon icon={faHouseChimney} />
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard/surveys">Projetos</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                {showSuccess && <Alert variant="success">{message}</Alert>}
                {showError && <Alert variant="danger">{message}</Alert>}
                <EditProjectModal
                  show={editModal}
                  project={editProject}
                  handleClose={handleClose}
                  message={showMessage}
                  list={listTecnicos}
                  fallback={updateListEditItem}
                />
                <NewProjectModal
                  show={show}
                  handleClose={handleClose}
                  message={showMessage}
                  list={listTecnicos}
                  fallback={updateListNewItem}
                />
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-11">
                            <h5>Surveys</h5>
                          </div>
                          <div className="col-md-1">
                            <button
                              type="button"
                              className="btn btn-primary"
                              data-toggle="tooltip"
                              data-original-title="btn btn-primary"
                              onClick={handleShow}
                              size="lg"
                            >
                              Novo
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-block table-border-style">
                        {showLoading ? (
                          <Loading />
                        ) : (
                          <div className="table-responsive">
                            <Table hover responsive>
                              <thead>
                                <tr>
                                  <th>
                                    Id{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("id")}
                                    />
                                  </th>
                                  <th>
                                    Título{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("plr")}
                                    />
                                  </th>
                                  <th>
                                    Empresa <FontAwesomeIcon icon={faSort} />
                                  </th>
                                  <th>Técnicos</th>
                                  <th>
                                    Data Inicio{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("createdDate")}
                                    />
                                  </th>
                                  <th>
                                    Data Fim{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("endDate")}
                                    />
                                  </th>
                                  <th>Download</th>
                                  <th>
                                    Status{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("status")}
                                    />
                                  </th>
                                </tr>
                              </thead>
                              {showErrorList ? (
                                <tbody>
                                  <tr>
                                    <td>Erro a obter informação</td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr id="search">
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Id"
                                          type="text"
                                          name="id"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="PLR"
                                          type="text"
                                          name="plr"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Empresa"
                                          type="text"
                                          name="company"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                          disabled
                                        />
                                        <span className="input-group-text">
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Técnico"
                                          type="text"
                                          name="assignedTechnicians"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Data inicio"
                                          type="date"
                                          name="createdDate"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Data fim"
                                          type="date"
                                          name="endDate"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th></th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <Form.Select
                                          size="sm"
                                          className="form-select-search"
                                          name="status"
                                          onChange={handleSearchInput}
                                          onBlur={handleSearchInput}
                                          onKeyDown={submitSearch}
                                        >
                                          <option value="0"> </option>
                                          <option value="1">Ativo</option>
                                          <option value="2">Finalizado</option>
                                        </Form.Select>
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                  </tr>
                                  {surveys.map((rowData, rowIndex) => {
                                    return (
                                      <tr key={rowIndex}>
                                        <td> {rowData.id} </td>
                                        <td> {rowData.plr} </td>
                                        <td> GeoInfra </td>
                                        <td>
                                          {rowData.assignedTechnicians
                                            ? rowData.assignedTechnicians.map(
                                                (u, index) => {
                                                  let o = (
                                                    <Badge
                                                      key={u.id}
                                                      pill
                                                      bg="secondary"
                                                    >
                                                      {u.username}
                                                    </Badge>
                                                  );
                                                  return index % 2 == 0
                                                    ? [o, <br />]
                                                    : o;
                                                }
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {new Date(
                                            rowData.createdDate
                                          ).toLocaleDateString("pt-PT")}
                                        </td>
                                        <td>
                                          {rowData.status === "CLOSED"
                                            ? rowData.endDate
                                              ? new Date(
                                                  rowData.endDate
                                                ).toLocaleDateString("pt-PT")
                                              : ""
                                            : ""}
                                        </td>
                                        <td>
                                          {rowData.status === "CLOSED" ? (
                                            <>
                                              <DownloadButton
                                                id={rowData.id}
                                                file="kml"
                                                plr={rowData.plr}
                                                message={showMessage}
                                              />
                                              <DownloadButton
                                                id={rowData.id}
                                                file="excel"
                                                plr={rowData.plr}
                                                message={showMessage}
                                              />
                                            </>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                        <td className="statusColum">
                                          <Form.Select
                                            size="sm"
                                            value={rowData.status}
                                            className={
                                              rowData.status === "OPEN"
                                                ? "aberto form_select"
                                                : "fechado form_select"
                                            }
                                            onChange={(e) =>
                                              handleStatus(
                                                rowData.id,
                                                e.target.value,
                                                rowIndex
                                              )
                                            }
                                          >
                                            <option
                                              className="surveys"
                                              value="OPEN"
                                            >
                                              Ativo
                                            </option>
                                            <option
                                              className="surveys"
                                              value="CLOSED"
                                            >
                                              Finalizado
                                            </option>
                                            <option
                                              className="surveys"
                                              value="EDIT"
                                            >
                                              Editar
                                            </option>
                                          </Form.Select>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                            <div
                              className="container"
                              style={{ marginTop: 10 }}
                            >
                              <div className="row justify-content-md-center">
                                <div className="col-md-2">
                                  <Form.Select
                                    size="sm"
                                    className="form_select aberto"
                                    id="dropdown-basic-button"
                                    style={{ boxShadow: "none" }}
                                    value={pageSize}
                                    onChange={(e) =>
                                      setPageSize(e.target.value)
                                    }
                                  >
                                    <option className="surveys" value="20">
                                      20
                                    </option>
                                    <option className="surveys" value="50">
                                      50
                                    </option>
                                    <option className="surveys" value="100">
                                      100
                                    </option>
                                  </Form.Select>
                                </div>
                                <div className="col-md-4">
                                  <Pagination>
                                    <Pagination.First
                                      onClick={firstPage}
                                      disabled={activePage === 0}
                                    />
                                    <Pagination.Prev
                                      onClick={previousPage}
                                      disabled={activePage === 0}
                                    />
                                    {items}
                                    <Pagination.Next
                                      onClick={nextPage}
                                      disabled={activePage === totalPages}
                                    />
                                    <Pagination.Last
                                      onClick={lastPage}
                                      disabled={activePage === totalPages}
                                    />
                                  </Pagination>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
