import React, { useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/login/Login";
import PrivateRoute from "./services/PrivateRoute";

function App() {
  const [token, setToken] = useState();
  if (token) {
    return <Login setToken={setToken} />;
  }

  return (
    <div className="App">
      <div className="page">
        <div className="content">
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Navigate to="/dashboard" />} />
              <Route
                path="/dashboard/*"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
