import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../services/Authentication";
import { Badge } from "react-bootstrap";
import { getHttp } from "../../../helpers/Http";
import Loading from "../../loading/Loading";
import DownloadButton from "../../exports/DownloadButton";

export default function ListOfSurveys() {
  const [showLoading, setShowLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [surveys, setSurveys] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    getHttp("projects?size=5&orderBy=id&sort=DESC")
      .then((data) => {
        setSurveys(data.content);
        setShowLoading(false);
      })
      .catch((e) => {
        if (e.code == 401) {
          logout().then(() => {
            history("/login");
          });
        }
        setShowLoading(false);
        setShowError(true);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="col-xl-12 col-md-12">
      <div className="card Recent-Users">
        <div className="card-header">
          <h5>Ultimos Surveys</h5>
        </div>
        <div className="card-block px-0 py-3">
          <div className="table-responsive">
            <div className="card-block table-border-style">
              {showLoading ? (
                <Loading />
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Id</th>
                        <th>PLR</th>
                        <th>Empresa</th>
                        <th>Técnico</th>
                        <th>Data Inicio</th>
                        <th>Data Fim</th>
                        <th>Download</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    {showError ? (
                      <tbody>
                        <tr>
                          <td>Erro a obter informação</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        {surveys.slice(0, 5).map((rowData, rowIndex) => {
                          return (
                            <tr key={rowIndex}>
                              <td> {rowData.id} </td>
                              <td> {rowData.plr} </td>
                              <td> GeoInfra </td>
                              <td>
                                {rowData.assignedTechnicians
                                  ? rowData.assignedTechnicians.map(
                                      (u, index) => {
                                        let o = (
                                          <Badge key={u.id} pill bg="secondary">
                                            {u.username}
                                          </Badge>
                                        );
                                        return index % 2 == 0 ? [o, <br />] : o;
                                      }
                                    )
                                  : ""}
                              </td>
                              <td>
                                {new Date(
                                  rowData.createdDate
                                ).toLocaleDateString("pt-PT")}
                              </td>
                              <td>
                                {rowData.status === "CLOSED"
                                  ? rowData.endDate
                                    ? new Date(
                                        rowData.endDate
                                      ).toLocaleDateString("pt-PT")
                                    : ""
                                  : ""}
                              </td>
                              <td>
                                {rowData.status === "CLOSED" ? (
                                  <>
                                    <DownloadButton
                                      id={rowData.id}
                                      file="kml"
                                      plr={rowData.plr}
                                    />
                                    <DownloadButton
                                      id={rowData.id}
                                      file="excel"
                                      plr={rowData.plr}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                {rowData.status === "OPEN" ? (
                                  <Badge bg="success">ABERTO</Badge>
                                ) : (
                                  <Badge bg="danger">FECHADO</Badge>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
