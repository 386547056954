import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import Loading from "../loading/Loading";
import Form from "react-bootstrap/Form";
import { getAuthState } from "../../services/Authentication";
import { patchHttp } from "../../helpers/Http";
import "./Users.css";

export default function User() {
  const [showLoading, setShowLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");

  const userAuth = getAuthState();

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "password":
          if (!value) {
            stateObj[name] = "Preencha a Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password e confirmação não coincidem.";
          } else if (value.length < 8) {
            stateObj[name] = "A Password deve conter pelo menos 8 caracteres";
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Campo obrigatório.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password e confirmação não coincidem.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const isEnabled =
    userAuth.role === "ROLE_ADMIN" &&
    input.confirmPassword.length > 0 &&
    input.password === input.confirmPassword;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.password.length > 0 && isEnabled) {
      var patch = [
        {
          op: "replace",
          path: "/password",
          value: input.password,
        },
      ];

      patchHttp("users/" + userAuth.id, patch)
        .then(() => {
          setMessage("Atualizado com sucesso!");
          setShowError(false);
          setShowSuccess(true);
        })
        .catch((e) => {
          console.log(e);
          setMessage("Erro a atulizar!");
          setShowSuccess(false);
          setShowError(true);
        });
    }
  };

  return (
    <div>
      <div className="pcoded-main-container_list">
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="page-header">
                <div className="page-block">
                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="page-header-title">
                        <h5 className="m-b-10">NPower Utilizadores</h5>
                      </div>
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <NavLink to="/dashboard">
                            <FontAwesomeIcon icon={faHouseChimney} />
                          </NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/dashboard">Dashboard</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <NavLink to="/dashboard/users">Utilizadores</NavLink>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="javascript:">Editar</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-body">
                <div className="page-wrapper">
                  {showSuccess && <Alert variant="success">{message}</Alert>}
                  {showError && <Alert variant="danger">{message}</Alert>}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-header">
                          <h5>Editar Utilizador</h5>
                        </div>
                        <div className="card-body">
                          <Form autoComplete="off" onSubmit={handleSubmit}>
                            <Container>
                              <Row>
                                <Col lg="6">
                                  <Form.Group>
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                      name="username"
                                      type="text"
                                      className="form-control"
                                      placeholder="Insira o Nome"
                                      autoComplete="off"
                                      defaultValue={
                                        userAuth ? userAuth.username : ""
                                      }
                                      disabled
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <Form.Label>Empresa</Form.Label>
                                    <Form.Select
                                      aria-label="Empresa"
                                      className="form-control"
                                      value="0"
                                      disabled
                                    >
                                      <option>Selecione a empresa</option>
                                      <option value="0">GeoInfra</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Select
                                      aria-label="Cargo"
                                      className="form-control"
                                      name="role"
                                      defaultChecked={
                                        userAuth ? userAuth.role : ""
                                      }
                                      value={
                                        userAuth.role ? userAuth.role : "0"
                                      }
                                      disabled
                                    >
                                      <option value="0">
                                        Selecione o cargo do novo utilizador
                                      </option>
                                      <option value="ROLE_DESIGNER">
                                        Desenhador
                                      </option>
                                      <option value="ROLE_TECHNICIAN">
                                        Tecnico
                                      </option>
                                      {userAuth.role === "ROLE_ADMIN" ? (
                                        <option value="ROLE_ADMIN" selected>
                                          Admin
                                        </option>
                                      ) : (
                                        ""
                                      )}
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              {userAuth.role === "ROLE_ADMIN" ? (
                                <Row>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>Password</Form.Label>
                                      <Form.Control
                                        name="password"
                                        type="password"
                                        className="form-control"
                                        placeholder="Insira a palavra chave"
                                        onChange={onInputChange}
                                        onBlur={() => validateInput}
                                        isInvalid={!!error.password}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {error.password}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Label>
                                        Confirme a Password
                                      </Form.Label>
                                      <Form.Control
                                        type="password"
                                        name="confirmPassword"
                                        className="form-control"
                                        placeholder="Confirmação"
                                        onChange={onInputChange}
                                        onBlur={() => validateInput}
                                        isInvalid={!!error.confirmPassword}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {error.confirmPassword}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              ) : (
                                ""
                              )}
                              <Row>
                                <Col>
                                  <br />
                                  {showLoading ? (
                                    <Loading />
                                  ) : (
                                    <Button
                                      type="submit"
                                      className="btn btn-primary"
                                      disabled={!isEnabled}
                                    >
                                      Guardar
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </Container>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
