import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, InputGroup, FormControl, Alert, Image } from "react-bootstrap";
import {
  faUser,
  faLock,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { login, isLoggedIn } from "../../services/Authentication";
import Form from "react-bootstrap/Form";
import Loading from "../loading/Loading";
import LoginImg from "./login.png";
import "./Login.css";

export default function Login() {
  // States for login
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [openError, setOpenError] = useState(false);
  const [textError, setTextError] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [iconEye, setIconEye] = useState(faEye);

  const history = useNavigate();

  const showPassword = () => {
    if (passwordType === "password") {
      setIconEye(faEyeSlash);
      setPasswordType("text");
    } else {
      setIconEye(faEye);
      setPasswordType("password");
    }
  };

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);
    setTextError("");
    setOpenError(false);
    login(name, password)
      .then((result) => {
        if (!result.result) {
          if (result.response === 403) {
            setShowLoading(false);
            setTextError("Não tem permissões para esta ferramenta.");
          } else if (result.response === 4030) {
            setShowLoading(false);
            setTextError("Utilizador desativado.");
          } else if (result.response >= 400 && result.response < 500) {
            setShowLoading(false);
            setTextError("Credenciais inválidas.");
          } else {
            setShowLoading(false);
            setTextError(
              "Ocorreu um erro... Caso continue contacte o adminstrador."
            );
          }
          setOpenError(true);
        } else if (result.result) {
          history("/dashboard");
        } else {
          setShowLoading(false);
          setTextError(
            "Ocorreu um erro... Caso continue contacte o adminstrador."
          );
          setOpenError(true);
        }
      })
      .catch(() => {
        setShowLoading(false);
        setTextError(
          "Ocorreu um erro... Caso continue contacte o adminstrador."
        );
        setOpenError(true);
      });
  };

  useEffect(() => {
    if (isLoggedIn()) {
      history("/dashboard");
    }
  }, []);

  return (
    <div>
      <div className="lcontainer">
        {/* Calling to the methods */}
        <div className="lItem">
          <div className="loginImage">
            <Image
              src={LoginImg}
              width="300"
              style={{ position: "relative" }}
              alt="login"
            />
          </div>
          <div className="loginForm">
            <h2>Entrar</h2>
            {openError && (
              <Alert variant="danger" className="login-form">
                {textError}
              </Alert>
            )}
            {showLoading ? (
              <Loading />
            ) : (
              <Form className="login-form" onSubmit={handleSubmit}>
                <Form.Group>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1" value={name}>
                      <FontAwesomeIcon icon={faUser} />
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Utilizador"
                      aria-label="Utilizador"
                      aria-describedby="basic-addon1"
                      onChange={handleName}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      type="password"
                      id="basic-addon11"
                      value={password}
                    >
                      <FontAwesomeIcon icon={faLock} />
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Password"
                      aria-label="Password"
                      type={passwordType}
                      aria-describedby="basic-addon11"
                      onChange={handlePassword}
                      required
                    />
                    <span className="input-group-text" onClick={showPassword}>
                      <FontAwesomeIcon icon={iconEye} />
                    </span>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    id="disabledFieldsetCheck"
                    label="Lembrar-me"
                  />
                  <Button
                    type="submit"
                    className="login-form-button btn-primary_login"
                  >
                    Entrar
                  </Button>
                </Form.Group>
              </Form>
            )}
          </div>
        </div>
        <div className="footer">
          <a
            href="https://andrade.pt"
            target="_blank"
            rel="noopener noreferrer"
            className="footerLink"
          >
            Powered by Andrade
          </a>
        </div>
      </div>
    </div>
  );
}
