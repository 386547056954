import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../services/Authentication";
import {
  faHouseChimney,
  faSort,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  Table,
  Pagination,
  Col,
  Row,
  Badge,
  Container,
  Alert,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Loading from "../loading/Loading";
import { getHttp, patchHttp } from "../../helpers/Http";
import "./Users.css";

export default function Users() {
  const [showLoading, setShowLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(0);
  const [items, setitems] = useState([]);
  const [show, setShow] = useState(false);
  const [editUser, setEditUser] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [id, setId] = useState(0);
  const [index, setIndex] = useState(0);
  const [orderBy, setOrderBy] = useState(0);

  const history = useNavigate();

  const handleClose = () => {
    setShow(false);
    setId(0);
    setEditUser("");
    setIndex(0);
  };

  const handleShow = (id, index) => {
    setEditUser(users[index]);
    setId(id);
    setIndex(index);
    setShow(true);
    setShowSuccess(false);
    setShowError(false);
  };

  const [filter, setFilter] = useState({
    id: "",
    username: "",
    tecnico: "",
    role: "",
    status: "",
  });

  const [input, setInput] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    role: 0,
  });

  const [error, setError] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const isEnabled =
    input.username.length > 0 &&
    input.password.length > 0 &&
    input.confirmPassword.length > 0 &&
    input.role !== 0 &&
    input.password === input.confirmPassword;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setShowSuccess(false);
    setShowError(false);
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Preencha o nome";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Preencha a Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password e confirmação não coincidem.";
          } else if (value.length < 8) {
            stateObj[name] = "A Password deve conter pelo menos 8 caracteres";
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Campo obrigatório.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password e confirmação não coincidem.";
          }
          break;

        case "role":
          if (!value || value === 0) {
            stateObj[name] = "Escolha um cargo";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  const nextPage = () => {
    requestUsers("users?page=" + (activePage + 1));
  };

  const previousPage = () => {
    requestUsers("users?page=" + (activePage - 1));
  };

  const lastPage = () => {
    requestUsers("users?page=" + totalPages);
  };

  const firstPage = () => {
    requestUsers("users?page=0");
  };

  const goToPage = (pageNumber) => {
    requestUsers("users" + pageNumber);
  };

  const createPagination = (total, active) => {
    setTotalPages(total - 1);
    setActivePage(active);
    for (let number = 1; number <= total; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => goToPage(number - 1)}
          disabled={active === totalPages}
        >
          {number}
        </Pagination.Item>
      );
    }
  };

  const updateListItem = (item) => {
    const nextUsers = users.map((c) => {
      if (c.id === item.id) {
        return item;
      } else {
        return c;
      }
    });
    setUsers(nextUsers);
  };

  const requestUsers = (path) => {
    getHttp(path)
      .then((data) => {
        setUsers(data.content);
        createPagination(data.totalPages, data.pageNumber);
        setShowLoading(false);
      })
      .catch((e) => {
        if (e.code == 401) {
          logout().then(() => {
            history("/login");
          });
        }
        setShowLoading(false);
        setShowError(true);
      });
  };

  const updateUser = (id, patch) => {
    // PATCH request using fetch inside useEffect React hook
    patchHttp("users/" + id, patch)
      .then(() => {
        setShow(false);
        setShowError(false);
        setShowSuccess(true);
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        setShowSuccess(false);
        setShowError(true);
        handleClose();
      });
  };

  const handleSort = (value) => {
    if (orderBy === 0) {
      setOrderBy(1);
      requestUsers("users?orderBy=" + value + "&sort=ASC");
    } else {
      setOrderBy(0);
      requestUsers("users?orderBy=" + value + "&sort=DESC");
    }
  };

  const handleSearchInput = (e) => {
    let { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submitSearch = (e) => {
    if (e.key === "Enter" || e.key === undefined) {
      var query = "users?";
      if (filter.id.length > 0) {
        query += "id=" + filter.id + "&";
      }
      if (filter.username.length > 0) {
        query += "username=" + filter.username + "&";
      }
      if (filter.role.length > 0) {
        if (filter.role === "1") {
          query += "role=ROLE_DESIGNER&";
        } else if (filter.role === "2") {
          query += "role=ROLE_TECHNICIAN&";
        }
      }
      if (filter.status.length > 0) {
        if (filter.status === "1") {
          query += "status=ENABLED&";
        } else if (filter.status === "2") {
          query += "status=DISABLED&";
        }
      }
      requestUsers(query);
    }
  };

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    requestUsers("users");

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  const handleDesactivate = () => {
    var patch = [];
    var updatedItem;

    if (editUser.status === "DISABLED") {
      patch[0] = { op: "replace", path: "/status", value: "ENABLED" };
      updatedItem = {
        ...editUser,
        status: "ENABLED",
      };
    } else {
      patch[0] = { op: "replace", path: "/status", value: "DISABLED" };
      updatedItem = {
        ...editUser,
        status: "DISABLED",
      };
    }
    updateUser(id, patch);
    updateListItem(updatedItem);
  };

  // Handling the edit submission
  const handleSubmit = (e) => {
    e.preventDefault();

    var patch = [];
    var username;
    var pass;
    var role;

    if (input.password.length > 0) {
      patch[patch.length] = {
        op: "replace",
        path: "/password",
        value: input.password,
      };
      pass = input.password;
    }

    if (input.username.length > 0 && input.username !== editUser.username) {
      patch[patch.length] = {
        op: "replace",
        path: "/username",
        value: input.username,
      };
      username = input.username;
    }

    if (input.role.length > 0 && input.role !== editUser.role) {
      patch[patch.length] = {
        op: "replace",
        path: "/role",
        value: input.role,
      };
      role = input.role;
    }

    const updatedItem = {
      ...editUser,
      username: username ? username : editUser.username,
      password: pass ? pass : editUser.password,
      role: role ? role : editUser.role,
    };

    updateUser(id, patch);
    updateListItem(updatedItem);
  };

  return (
    <div>
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">NPower Utilizadores</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">
                          <FontAwesomeIcon icon={faHouseChimney} />
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard/users">Utilizadores</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                <Modal show={show} onHide={handleClose}>
                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Modal.Header closeButton>
                      <Modal.Title>Editar Utilizador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Container>
                        <Row>
                          <Col lg="6">
                            <Form.Group>
                              <Form.Label>Username</Form.Label>
                              <Form.Control
                                disabled={editUser.status === "DISABLED"}
                                name="username"
                                type="text"
                                className="form-control"
                                placeholder="Insira o Nome"
                                onChange={onInputChange}
                                onBlur={() => validateInput}
                                autoComplete="off"
                                isInvalid={!!error.username}
                                defaultValue={editUser.username}
                              />
                              <Form.Control.Feedback type="invalid">
                                {error.username}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Cargo</Form.Label>
                              <Form.Select
                                disabled={editUser.status === "DISABLED"}
                                aria-label="Cargo"
                                className="form-control"
                                name="role"
                                onChange={onInputChange}
                                onBlur={() => validateInput}
                                isInvalid={!!error.role}
                                defaultValue={editUser.role}
                              >
                                <option value="0">
                                  Selecione o cargo do novo utilizador
                                </option>
                                <option value="ROLE_DESIGNER">
                                  Desenhador
                                </option>
                                <option value="ROLE_TECHNICIAN">Tecnico</option>
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {error.role}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Nova Password</Form.Label>
                              <Form.Control
                                disabled={editUser.status === "DISABLED"}
                                name="password"
                                type="password"
                                className="form-control"
                                placeholder="Insira a palavra chave"
                                onChange={onInputChange}
                                onBlur={() => validateInput}
                                isInvalid={!!error.password}
                              />
                              <Form.Control.Feedback type="invalid">
                                {error.password}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Confirme a nova Password</Form.Label>
                              <Form.Control
                                disabled={editUser.status === "DISABLED"}
                                type="password"
                                name="confirmPassword"
                                className="form-control"
                                placeholder="Confirmação"
                                onChange={onInputChange}
                                onBlur={() => validateInput}
                                isInvalid={!!error.confirmPassword}
                              />
                              <Form.Control.Feedback type="invalid">
                                {error.confirmPassword}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="me-auto">
                        {editUser.status === "DISABLED" ? (
                          <Button
                            variant="outline-success"
                            onClick={handleDesactivate}
                          >
                            Activar
                          </Button>
                        ) : (
                          <Button
                            variant="outline-danger"
                            onClick={handleDesactivate}
                          >
                            Desactivar
                          </Button>
                        )}
                      </div>
                      <Button variant="secondary" onClick={handleClose}>
                        Fechar
                      </Button>
                      <Button
                        className="btn-primary_modal"
                        type="submit"
                        disabled={isEnabled}
                      >
                        Guardar
                      </Button>
                    </Modal.Footer>
                  </Form>
                </Modal>
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <div className="row">
                          <div className="col-md-11">
                            <h5>Utilizadores</h5>
                          </div>
                        </div>
                      </div>
                      <div className="card-block table-border-style">
                        {showSuccess && (
                          <Alert variant="success">
                            Utilizador atualizado com sucesso!
                          </Alert>
                        )}
                        {showError && (
                          <Alert variant="danger">
                            Erro ao atualizar o utilizador.
                          </Alert>
                        )}
                        {showLoading ? (
                          <Loading />
                        ) : (
                          <div className="table-responsive">
                            <Table className="table table-hover" responsive>
                              <thead>
                                <tr>
                                  <th>
                                    Username{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("username")}
                                    />
                                  </th>
                                  <th>
                                    Role{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("role")}
                                    />
                                  </th>
                                  <th>
                                    Empresa <FontAwesomeIcon icon={faSort} />
                                  </th>
                                  <th>
                                    Status{" "}
                                    <FontAwesomeIcon
                                      icon={faSort}
                                      onClick={() => handleSort("status")}
                                    />
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              {showError ? (
                                <tbody>
                                  <tr>
                                    <td>Erro a obter informação</td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr className="search" id="search">
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          type="text"
                                          name="username"
                                          onChange={handleSearchInput}
                                          onKeyDown={submitSearch}
                                          size="sm"
                                        />
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <Form.Select
                                          className="form-select-search"
                                          name="role"
                                          onChange={handleSearchInput}
                                          onBlur={handleSearchInput}
                                          onKeyDown={submitSearch}
                                        >
                                          <option value="0"> </option>
                                          <option value="1">Desenhador</option>
                                          <option value="2">Tecnico</option>
                                        </Form.Select>
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <FormControl
                                          placeholder="Empresa"
                                          type="text"
                                          name="company"
                                          size="sm"
                                          disabled
                                        />
                                        <span className="input-group-text">
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                    <th>
                                      <InputGroup className="mb-3">
                                        <Form.Select
                                          className="form-select-search"
                                          name="status"
                                          onChange={handleSearchInput}
                                          onBlur={handleSearchInput}
                                          onKeyDown={submitSearch}
                                        >
                                          <option value="0"> </option>
                                          <option value="1">Ativo</option>
                                          <option value="2">Desativado</option>
                                        </Form.Select>
                                        <span
                                          className="input-group-text"
                                          onClick={submitSearch}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <FontAwesomeIcon icon={faSearch} />
                                        </span>
                                      </InputGroup>
                                    </th>
                                  </tr>
                                  {users.map((rowData, rowIndex) => {
                                    return (
                                      <tr key={rowIndex}>
                                        <td> {rowData.username} </td>
                                        <td>
                                          {rowData.role === "ROLE_DESIGNER"
                                            ? "Desenhador"
                                            : ""}
                                          {rowData.role === "ROLE_TECHNICIAN"
                                            ? "Técnico"
                                            : ""}
                                          {rowData.role === "ROLE_MANAGER"
                                            ? "Manager"
                                            : ""}
                                        </td>
                                        <td> GeoInfra </td>
                                        <td>
                                          {rowData.status === "ENABLED" ? (
                                            <Badge bg="success">Ativo</Badge>
                                          ) : (
                                            <Badge bg="danger">
                                              Desativado
                                            </Badge>
                                          )}
                                        </td>
                                        <td>
                                          <Button
                                            variant="light"
                                            size="sm"
                                            onClick={() =>
                                              handleShow(rowData.id, rowIndex)
                                            }
                                          >
                                            Editar
                                          </Button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              )}
                            </Table>
                            <div className="float-end">
                              <Pagination>
                                <Pagination.First
                                  onClick={firstPage}
                                  disabled={activePage === totalPages}
                                />
                                <Pagination.Prev
                                  onClick={previousPage}
                                  disabled={activePage === totalPages}
                                />
                                {items}
                                <Pagination.Next
                                  onClick={nextPage}
                                  disabled={activePage === totalPages}
                                />
                                <Pagination.Last
                                  onClick={lastPage}
                                  disabled={activePage === totalPages}
                                />
                              </Pagination>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
