import React from "react";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NumbSurveysOpen({ number }) {
  return (
    <div className="col-md-6 col-xl-4">
      <div className="card daily-sales">
        <div className="card-block">
          <h6 className="mb-4">Projetos abertos</h6>
          <div className="row d-flex align-items-center">
            <div className="col-9">
              <h3 className="f-w-300 d-flex align-items-center m-b-0">
                <FontAwesomeIcon
                  icon={faArrowUp}
                  className="feather text-c-green f-30 m-r-10"
                />{" "}
                {number}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
