import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { postHttp } from "../../helpers/Http";
import Loading from "../loading/Loading";
import "./Users.css";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";

export default function NewUser() {
  const [success, setSuccess] = useState(false);
  const [erro, setErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [input, setInput] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    role: 0,
  });

  const [error, setError] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    role: "",
  });

  const isEnabled =
    input.username.length > 0 &&
    input.password.length > 0 &&
    input.confirmPassword.length > 0 &&
    input.role !== 0 &&
    input.password === input.confirmPassword;

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setSuccess(false);
    setErro(false);
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Preencha o nome";
          } else if (value.length < 3) {
            stateObj[name] = "A Username deve conter pelo menos 3 caracteres";
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = "Preencha a Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Password e confirmação não coincidem.";
          } else if (value.length < 8) {
            stateObj[name] = "A Password deve conter pelo menos 8 caracteres";
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Campo obrigatório.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password e confirmação não coincidem.";
          }
          break;

        case "role":
          if (!value || value == 0) {
            stateObj[name] = "Escolha um cargo";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  };

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    validateInput(e);

    if (!isEnabled) {
      setErro(true);
      return;
    }

    setShowLoading(true);

    const data = {
      username: "",
      password: "",
      role: "",
    };

    if (input.role === "1") {
      data.role = "ROLE_DESIGNER";
    } else if (input.role === "2") {
      data.role = "ROLE_TECHNICIAN";
    }

    data.username = input.username;
    data.password = input.password;

    // Remove old warnings
    setError(false);
    setSuccess(false);

    // POST request using fetch inside useEffect React hook
    postHttp("users", data)
      .then(() => {
        setShowLoading(false);
        setSuccess(true);
        Array.from(document.querySelectorAll("input")).forEach(
          (input) => (input.value = "")
        );
        setInput({ username: "", password: "", confirmPassword: "", role: "" });
      })
      .catch((e) => {
        console.log(e);
        setShowLoading(false);
        setSuccess(false);
        setErro(true);
      });
  };

  return (
    <div>
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">NPower Utilizadores</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">
                          <FontAwesomeIcon icon={faHouseChimney} />
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard/utilizadores">
                          Utilizadores
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="javascript:">Novo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Novo Utilizador</h5>
                      </div>
                      <div className="card-body">
                        {success && (
                          <Alert variant="success">
                            Utilizador criado com sucesso!
                          </Alert>
                        )}
                        {erro && (
                          <Alert variant="danger">
                            Erro ao criar o utilizador.
                          </Alert>
                        )}
                        <Form onSubmit={handleSubmit} autoComplete="off">
                          <Container>
                            <Row>
                              <Col lg="6">
                                <Form.Group>
                                  <Form.Label>Username</Form.Label>
                                  <Form.Control
                                    name="username"
                                    type="text"
                                    className="form-control"
                                    placeholder="Insira o Nome"
                                    onChange={onInputChange}
                                    onBlur={() => validateInput}
                                    autoComplete="off"
                                    isInvalid={!!error.username}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {error.username}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Empresa</Form.Label>
                                  <Form.Select
                                    aria-label="Empresa"
                                    className="form-control"
                                  >
                                    <option>Selecione a empresa</option>
                                    <option value="0">GeoInfra</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Cargo</Form.Label>
                                  <Form.Select
                                    aria-label="Cargo"
                                    className="form-control"
                                    name="role"
                                    onChange={onInputChange}
                                    onBlur={() => validateInput}
                                    isInvalid={!!error.role}
                                  >
                                    <option value="0">
                                      Selecione o cargo do novo utilizador
                                    </option>
                                    <option value="1">Desenhador</option>
                                    <option value="2">Tecnico</option>
                                  </Form.Select>
                                  <Form.Control.Feedback type="invalid">
                                    {error.role}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Password</Form.Label>
                                  <Form.Control
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Insira a palavra chave"
                                    onChange={onInputChange}
                                    onBlur={() => validateInput}
                                    isInvalid={!!error.password}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {error.password}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Confirme a Password</Form.Label>
                                  <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirmação"
                                    onChange={onInputChange}
                                    onBlur={() => validateInput}
                                    isInvalid={!!error.confirmPassword}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {error.confirmPassword}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <br />
                                {showLoading ? (
                                  <Loading />
                                ) : (
                                  <Button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!isEnabled}
                                  >
                                    Criar
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </Container>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
