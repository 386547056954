import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { patchHttp } from "../../helpers/Http";

export default function EditProjectModal({
  show,
  project,
  handleClose,
  message,
  list,
  fallback,
}) {
  const [plrError, setPlrError] = useState("");
  const [input, setInput] = useState({
    plr: project.plr,
    assignedTechnicians: [],
  });

  const validateSubmit = input.plr.length > 3 || project.plr.length > 3;

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "plr" && value.length < 3)
      setPlrError("PLR deve conter pelo menos 3 caracteres");
    else setPlrError("");
  };

  // Handling the tecnicos change
  const handleTechnicians = (e) => {
    setInput((prev) => ({
      ...prev,
      assignedTechnicians: Array.from(
        e.target.selectedOptions,
        (option) => option.value
      ),
    }));
  };

  const TechnicianList = function (tecnicos) {
    const id = parseInt(tecnicos);
    return { id };
  };

  // Handling the new project submission
  const handleEdit = (e) => {
    e.preventDefault();

    if (!validateSubmit) {
      return false;
    }

    var patch = [];

    if (input.plr.length > 3) {
      patch[patch.length] = { op: "replace", path: "/plr", value: input.plr };
    }

    if (
      input.assignedTechnicians.length > 0 &&
      input.assignedTechnicians !== project.assignedTechnicians
    ) {
      patch[patch.length] = {
        op: "replace",
        path: "/assignedTechnicians",
        value: input.assignedTechnicians.map((t) => TechnicianList(t)),
      };
    }

    patchHttp("projects/" + project.id, patch)
      .then((data) => {
        message(1, "Projeto atualizado com sucesso!");
        fallback(data);
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        if (e.status === 409) {
          message(2, "Erro: Já existe um projeto com o mesmo título!");
        } else {
          message(2, "Erro ao criar o projeto.");
        }
        fallback("");
        handleClose();
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Projeto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control
                  name="plr"
                  type="text"
                  defaultValue={project ? project.plr : ""}
                  className="form-control"
                  placeholder="Insira o PLR"
                  onChange={onInputChange}
                  onBlur={() => onInputChange}
                  isInvalid={!!plrError}
                />
                <Form.Control.Feedback type="invalid">
                  {plrError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Equipa</Form.Label>
                <Form.Select
                  aria-label="Equipas"
                  name="assignedTechnicians"
                  className="form-control"
                  onChange={handleTechnicians}
                  defaultValue={
                    project.assignedTechnicians
                      ? project.assignedTechnicians.map((x) => x.id)
                      : ""
                  }
                  multiple
                >
                  {list
                    .sort((a, b) => a.username.localeCompare(b.username))
                    .map((tecnico) => (
                      <option key={tecnico.id} value={tecnico.id}>
                        {tecnico.username}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <br />
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Empresa</Form.Label>
                <Form.Select
                  aria-label="Empresa"
                  className="form-control"
                  placeholder="Selecione a empresa"
                  value="0"
                  readOnly
                >
                  <option>Selecione a empresa</option>
                  <option value="0">GeoInfra</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            className="btn-primary_modal"
            type="submit"
            disabled={!validateSubmit}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
