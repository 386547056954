import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { getHttp, postHttp } from "../../helpers/Http";
import Loading from "../loading/Loading";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "./Surveys.css";

export default function NewSurveys() {
  // States for Survey
  const [plrError, setPlrError] = useState();
  const [listTecnicos, setListTecnicos] = useState([]);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const Data = function (plrs, tecnicos) {
    const plr = plrs;
    const status = "OPEN";
    const assignedTechnicians = tecnicos.map((x) => parseInt(x));
    return { plr, status, assignedTechnicians };
  };

  const [input, setInput] = useState({
    plr: "",
    assignedTechnicians: [],
  });

  const validateSubmit = input.plr.length >= 3;

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "plr" && value.length < 3)
      setPlrError("PLR deve conter pelo menos 3 caracteres");
    else setPlrError("");
  };

  // Handling the tecnicos change
  const handleTechnicians = (e) => {
    setInput((prev) => ({
      ...prev,
      assignedTechnicians: Array.from(
        e.target.selectedOptions,
        (option) => option.value
      ),
    }));
  };

  // Get Technician to associate project
  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    getHttp("users/role/ROLE_TECHNICIAN?userStatus=ENABLED")
      .then((data) => {
        setListTecnicos(data);
      })
      .catch(() => {
        setError(true);
      });

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  // Handling the form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowLoading(true);

    // Remove old warnings
    setError(false);
    setSuccess(false);

    const data = Data(input.plr, input.assignedTechnicians);

    // POST request using fetch inside useEffect React hook
    postHttp("projects", data)
      .then(() => {
        setShowLoading(false);
        setSuccess(true);
      })
      .catch((e) => {
        setShowLoading(false);
        if (e.status === 409) {
          setErrorMessage("Erro: Já existe um projeto com o mesmo titulo!");
          setError(true);
        } else {
          setErrorMessage("Erro ao criar o projeto.");
          setError(true);
        }
      });
  };

  return (
    <div>
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="pcoded-inner-content">
            <div className="page-header">
              <div className="page-block">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="page-header-title">
                      <h5 className="m-b-10">NPower Projetos</h5>
                    </div>
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">
                          <FontAwesomeIcon icon={faHouseChimney} />
                        </NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard">Dashboard</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <NavLink to="/dashboard/surveys">Projetos</NavLink>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="javascript:">Novo</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-body">
              <div className="page-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h5>Novo Projeto</h5>
                      </div>
                      <div className="card-body">
                        {success && (
                          <Alert variant="success">
                            Projeto criado com sucesso!
                          </Alert>
                        )}
                        {error && (
                          <Alert variant="danger">{errorMessage}</Alert>
                        )}
                        <Container>
                          <Form onSubmit={handleSubmit}>
                            <Row>
                              <Col>
                                <Form.Group>
                                  <Form.Label>Título</Form.Label>
                                  <Form.Control
                                    name="plr"
                                    type="text"
                                    className="form-control"
                                    placeholder="Insira o PLR"
                                    onChange={onInputChange}
                                    onBlur={onInputChange}
                                    isInvalid={!!plrError}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {plrError}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Col>
                              <Col>
                                <div className="form-group">
                                  <label>Empresa</label>
                                  <Form.Select
                                    aria-label="Empresa"
                                    className="form-control"
                                  >
                                    <option>Selecione a empresa</option>
                                    <option value="0">GeoInfra</option>
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="form-group">
                                  <label>Equipa</label>
                                  <Form.Select
                                    aria-label="Equipas"
                                    className="form-control"
                                    onChange={handleTechnicians}
                                    name="assignedTechnicians"
                                    multiple
                                  >
                                    {showLoading ? (
                                      <Loading />
                                    ) : (
                                      listTecnicos
                                        .sort((a, b) =>
                                          a.username.localeCompare(b.username)
                                        )
                                        .map((tecnico) => (
                                          <option value={tecnico.id}>
                                            {tecnico.username}
                                          </option>
                                        ))
                                    )}
                                  </Form.Select>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <br />
                                {showLoading ? (
                                  <Loading />
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!validateSubmit}
                                  >
                                    Criar
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
