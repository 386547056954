import React, { useState } from "react";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Dashboard.css";
import Home from "./home/Home";
import Nav from "./nav/Nav";
import Surveys from "../surveys/Surveys";
import { Route, Routes } from "react-router-dom";
import NewSurveys from "../surveys/NewSurvey";
import NewUser from "../users/NewUser";
import Users from "../users/Users";
import User from "../users/User";

export default function Dashboard() {
  const [size, setSize] = useState(false);

  const a = (b) => {
    if (b) {
      setSize(true);
    } else {
      setSize(false);
    }
  };

  return (
    <div>
      <Nav size={a} />
      {/*<Header />*/}
      <div
        className={
          size ? "pcoded-main-container body-hide" : "pcoded-main-container"
        }
      >
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body">
                <div className="page-wrapper"></div>
                <Routes>
                  <Route path="/" element={<Home />}></Route>
                  <Route path="/surveys" element={<Surveys />}></Route>
                  <Route path="/survey/new" element={<NewSurveys />}></Route>
                  <Route path="/user/new" element={<NewUser />}></Route>
                  <Route path="/users" element={<Users />}></Route>
                  <Route path="/user" element={<User />}></Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
