import React, { useState, useEffect } from "react";
import NumbSurveys from "../numbSurveys/NumbSurveys";
import NumbTablets from "../numbTablets/NumbTablets";
import NumbSurveysOpen from "../numbSurveysOpen/NumbSurveysOpen";
import ListOfSurveys from "../listSurveys/ListOfSurveys";
import { getHttp } from "../../../helpers/Http";

export default function Home() {
  const [data, setData] = useState({
    totalProjects: 0,
    totalProjectsOpen: 0,
    totalActiveUsers: 0,
  });

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
    getHttp("companies/dashboard/count")
      .then((data) => {
        setData(data);
      })
      .catch(() => {});

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div>
      <div className="row">
        <NumbSurveys number={data.totalProjects} />
        <NumbSurveysOpen number={data.totalProjectsOpen} />
        <NumbTablets number={data.totalActiveUsers} />
      </div>
      <div className="row">
        <ListOfSurveys />
      </div>
    </div>
  );
}
