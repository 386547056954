import React, { useState } from "react";
import excelIcon from "./excel.png";
import kmlIcon from "./kml.png";
import { getBinaryHttp } from "../../helpers/Http";
import Image from "react-bootstrap/Image";
import Loading from "../loading/Loading";

export default function DownloadButton({ id, file, plr, message }) {
  const [show1Loading, setShow1Loading] = useState(false);

  const DownloadFile = (id) => {
    setShow1Loading(true);
    getBinaryHttp("projects/" + id + "/" + file)
      .then((blob) => {
        console.log(blob);

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);

        if (file === "excel") {
          a.setAttribute("download", `${plr}.xlsx`);
        }
        if (file === "kml") {
          a.setAttribute("download", `${plr}.kml`);
        }

        const clickEvt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        a.dispatchEvent(clickEvt);
        a.remove();
        message(1, "Download a iniciar ...");
        setShow1Loading(false);
      })
      .catch((e) => {
        message(2, "Erro a carregar KML, por favor contacte o administrador.");
        console.log("Erro a carregar " + file + ": " + e);
        setShow1Loading(false);
      });
  };

  return show1Loading ? (
    <Loading style={{ display: "inline-block" }} />
  ) : (
    <Image
      fluid="true"
      src={file === "excel" ? excelIcon : kmlIcon}
      width="30px"
      style={{ marginRight: "1em", cursor: "pointer" }}
      onClick={() => DownloadFile(id)}
    />
  );
}
