import React, { useState, useEffect } from "react";
import pic from "./icon.png";
import { useNavigate } from "react-router-dom";
import {
  faCirclePlus,
  faHouse,
  faMapLocationDot,
  faUserGear,
  faUserPen,
  faPowerOff,
  faFileCirclePlus,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, useLocation } from "react-router-dom";
import { logout } from "../../../services/Authentication";
import "./Nav.css";
import { getAuthState } from "../../../services/Authentication";

export default function Nav({ size }) {
  const [show, setShow] = useState(true);

  const handleResize = () => {
    if (window.innerWidth < 950) {
      size(true);
      setShow(false);
    } else {
      size(false);
      setShow(true);
    }
  };

  const handleShow = () => {
    if (show) {
      size(true);
      setShow(false);
    } else {
      size(false);
      setShow(true);
    }
  };
  const location = useLocation();
  const history = useNavigate();

  // Logout
  const handleLogout = () => {
    logout().then(() => {
      history("/login");
    });
  };

  const { role } = getAuthState();

  const getNavLinkClass = (path) => {
    return location.pathname === path ? "nav-item active" : "nav-item";
  };

  // create an event listener
  useEffect(() => {
    handleResize();
  }, []);

  return (
    <nav className={show ? "pcoded-navbar" : "pcoded-navbar navbar-hide"}>
      <div className="navbar-wrapper">
        <div
          className={
            show
              ? "navbar-brand header-logo"
              : "navbar-hide navbar-brand header-logo"
          }
        >
          <NavLink to="/dashboard" className="b-brand">
            <div className="b-bg">
              <img style={{ width: "65%" }} src={pic} alt="Logo" />
            </div>
            {show ? <span className="b-title">Power</span> : ""}
          </NavLink>
          {show ? (
            <a
              className="mobile-menu"
              id="mobile-collapse"
              onClick={handleShow}
              style={{ cursor: "pointer" }}
            >
              <span></span>
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="navbar-content scroll-div">
          <ul className="nav pcoded-inner-navbar">
            {show ? (
              <li className="nav-item pcoded-menu-caption">
                <label>Navegação</label>
              </li>
            ) : (
              ""
            )}
            <li className={getNavLinkClass("/dashboard")}>
              <NavLink to="/dashboard" className="nav-link">
                <span className="pcoded-micon">
                  <FontAwesomeIcon icon={faHouse} />
                </span>
                {show ? <span className="pcoded-mtext">Dashboard</span> : ""}
              </NavLink>
            </li>
            {show ? (
              <li className="nav-item pcoded-menu-caption">
                <label>Projetos</label>
              </li>
            ) : (
              ""
            )}
            <li className={getNavLinkClass("/dashboard/surveys")}>
              <NavLink to="/dashboard/surveys" className="nav-link">
                <span className="pcoded-micon">
                  <FontAwesomeIcon icon={faMapLocationDot} />
                </span>
                {show ? <span className="pcoded-mtext">Lista</span> : ""}
              </NavLink>
            </li>
            <li className={getNavLinkClass("/dashboard/survey/new")}>
              <NavLink to="/dashboard/survey/new" className="nav-link ">
                <span className="pcoded-micon">
                  <FontAwesomeIcon icon={faFileCirclePlus} />
                </span>
                {show ? <span className="pcoded-mtext">Novo</span> : ""}
              </NavLink>
            </li>
            {role === "ROLE_ADMIN" || role === "ROLE_MANAGER" ? (
              <>
                {show ? (
                  <li className="nav-item pcoded-menu-caption">
                    <label>Utilizadores</label>
                  </li>
                ) : (
                  ""
                )}
                <li className={getNavLinkClass("/dashboard/users")}>
                  <NavLink to="/dashboard/users" className="nav-link ">
                    <span className="pcoded-micon">
                      <FontAwesomeIcon icon={faUserPen} />
                    </span>
                    {show ? <span className="pcoded-mtext">Lista</span> : ""}
                  </NavLink>
                </li>
                <li className={getNavLinkClass("/dashboard/user/new")}>
                  <NavLink to="/dashboard/user/new" className="nav-link ">
                    <span className="pcoded-micon">
                      <FontAwesomeIcon icon={faCirclePlus} />
                    </span>
                    {show ? <span className="pcoded-mtext">Criar</span> : ""}
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}

            {show ? (
              <li className="nav-item pcoded-menu-caption">
                <label>Definições</label>
              </li>
            ) : (
              ""
            )}
            <li className={getNavLinkClass("/dashboard/user")}>
              <NavLink to="/dashboard/user" className="nav-link ">
                <span className="pcoded-micon">
                  <FontAwesomeIcon icon={faUserGear} />
                </span>
                {show ? <span className="pcoded-mtext">Conta</span> : ""}
              </NavLink>
            </li>
            <li className="nav-item">
              <a
                onClick={handleLogout}
                className="nav-link"
                style={{ cursor: "pointer" }}
              >
                <span className="pcoded-micon">
                  <FontAwesomeIcon icon={faPowerOff} />
                </span>
                {show ? <span className="pcoded-mtext">Logout</span> : ""}
              </a>
            </li>

            {!show ? (
              <li className="nav-item navbar-footer">
                <a
                  className="nav-link"
                  id="mobile-collapse"
                  onClick={handleShow}
                  style={{ cursor: "pointer" }}
                >
                  <span className="pcoded-micon">
                    <FontAwesomeIcon icon={faAnglesRight} />
                  </span>
                </a>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}
