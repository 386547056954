import { validateUser, refreshToken } from "../helpers/Http";
import jwt_decode from "jwt-decode";

export const getAuthState = () => {
  return {
    userToken: localStorage.getItem("accessToken"),
    username: localStorage.getItem("username"),
    role: localStorage.getItem("roles"),
    refreshToken: localStorage.getItem("refreshToken"),
    id: localStorage.getItem("id"),
  };
};

export const login = (username, password) => {
  return validateUser(username, password)
    .then((response) => {
      if (response.status === 200) {
        if (response.body.roles.some((x) => x === "ROLE_TECHNICIAN")) {
          return { result: false, response: 403 };
        }
        if (response.body.status === "DISABLED") {
          return { result: false, response: 4030 };
        }
        localStorage.setItem("accessToken", response.body.accessToken);
        localStorage.setItem("username", response.body.username);
        localStorage.setItem("roles", response.body.roles);
        localStorage.setItem("refreshToken", response.body.refreshToken);
        localStorage.setItem("id", response.body.userId);
        return { result: true };
      } else {
        return { result: false, response: response.status };
      }
    })
    .catch((error) => {
      return {
        error: error,
        result: false,
      };
    });
};

export const logout = () => {
  return new Promise((resolve) => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("username");
    localStorage.removeItem("roles");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("id");
    localStorage.clear();
    resolve(true);
  });
};

export const isLoggedIn = () => {
  const token = localStorage.getItem("accessToken");

  if (token === "undefined" || token === null) {
    logout();
    return false;
  }
  if (jwt_decode(token).exp <= Date.now() / 1000) {
    refreshToken(localStorage.getItem("refreshToken"))
      .then((user) => {
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("refreshToken", user.refreshToken);
        return true;
      })
      .catch(() => {
        logout();
        return false;
      });
  }
  return true;
};
