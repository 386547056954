import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { postHttp } from "../../helpers/Http";
import Form from "react-bootstrap/Form";

export default function NewProjectModal({
  handleClose,
  show,
  message,
  list,
  fallback,
}) {
  const [plrError, setPlrError] = useState("");

  const [input, setInput] = useState({
    plr: "",
    assignedTechnicians: [],
  });

  const Data = function (plrs, tecnicos) {
    const plr = plrs;
    const status = "OPEN";
    const assignedTechnicians = tecnicos.map((x) => parseInt(x));
    return { plr, status, assignedTechnicians };
  };

  // Handling the tecnicos change
  const handleTechnicians = (e) => {
    setInput((prev) => ({
      ...prev,
      assignedTechnicians: Array.from(
        e.target.selectedOptions,
        (option) => option.value
      ),
    }));
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "plr" && value.length < 3)
      setPlrError("Título deve conter pelo menos 3 caracteres");
    else setPlrError("");
  };

  const validateSubmit = input.plr.length >= 3;

  const handleSubmit1 = (e) => {
    e.preventDefault();
    const data = Data(input.plr, input.assignedTechnicians);

    // POST request using fetch inside useEffect React hook
    postHttp("projects", data)
      .then((data) => {
        message(1, "Projeto criado com sucesso!");
        fallback(data);
        handleClose();
      })
      .catch((e) => {
        if (e.status === 409) {
          message(2, "Erro: Já existe um projeto com o mesmo título!");
        } else {
          message(2, "Erro ao criar o projeto.");
        }
        fallback("");
        handleClose();
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Form onSubmit={handleSubmit1}>
        <Modal.Header closeButton>
          <Modal.Title>Novo Projeto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Título</Form.Label>
                <Form.Control
                  name="plr"
                  type="text"
                  className="form-control"
                  placeholder="Insira o PLR"
                  onChange={onInputChange}
                  onBlur={() => onInputChange}
                  isInvalid={!!plrError}
                />
                <Form.Control.Feedback type="invalid">
                  {plrError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Equipa</Form.Label>
                <Form.Select
                  name="assignedTechnicians"
                  aria-label="Equipas"
                  className="form-control"
                  onChange={handleTechnicians}
                  multiple
                >
                  {list
                    .sort((a, b) => a.username.localeCompare(b.username))
                    .map((tecnico) => (
                      <option key={tecnico.id} value={tecnico.id}>
                        {tecnico.username}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
              <br />
            </div>
            <div className="col-md-6">
              <Form.Group>
                <Form.Label>Empresa</Form.Label>
                <Form.Select
                  aria-label="Empresa"
                  className="form-control"
                  placeholder="Selecione a empresa"
                >
                  <option>Selecione a empresa</option>
                  <option value="0">GeoInfra</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
          <Button
            className="btn-primary_modal"
            type="submit"
            disabled={!validateSubmit}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
